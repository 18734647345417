<template>
  <div class="row">
    <div
      :class="[
        { 'col-xl-10': additionalPrefix == null },
        { 'col-xl-8': additionalPrefix != null }
      ]"
    >
      <label :class="{ 'font-weight-bold text-dark': isBold }">
        {{ name }}
      </label>
    </div>
    <div v-if="additionalPrefix != null" class="col-xl-2 text-right">
      <label :class="{ 'font-weight-bold text-dark': isBold }">
        {{ additionalPrefix }}
      </label>
    </div>
    <div class="col-xl-2 text-right">
      <label :class="{ 'font-weight-bold text-dark': isBold }"
        >{{ prefix }}
        {{ property }}
        {{ postfix }}</label
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscriptionPropertyLine",
  props: {
    name: {
      String,
      required: true
    },
    prefix: {
      String,
      default: ""
    },
    property: {
      required: true
    },
    postfix: {
      String,
      default: ""
    },
    isBold: {
      Boolean,
      default: false
    },
    additionalPrefix: {
      String,
      default: null
    }
  }
};
</script>

<style scoped></style>
