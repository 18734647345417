<template>
  <div v-if="isLoaded">
    <!--begin::Notice-->
    <b-jumbotron
      v-if="!subscriptionOrder.is_adjustment_allowed"
      bg-variant="info"
      text-variant="white"
      border-variant="light"
    >
      <template v-slot:lead>
        Bitte beachten Sie, dass Sie während der aktuellen Vertragslaufzeit
        keine Anpassungen an Ihrem Abonement vornehmen können. Sie können 30 Tag
        vor dem Ende des aktuellen Vertrages Ihr Abonement anpassen. Dies wird
        ab dem
        <strong>{{ formatDateTime(subscriptionOrder.adjustment_date) }}</strong>
        möglich sein.
      </template>
      <hr class="my-4" />
      <p>
        Sie können jedoch weitere Optionen jederzeit zu Ihrem Abonoment
        hinzufügen.
      </p>
      <router-link
        to="/add-subscription-option"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <b-button variant="success" @click="navigate"
          >Optionen hinzufügen
        </b-button>
      </router-link>
    </b-jumbotron>
    <!--end::Notice-->
    <!--begin::Card-->
    <div class="card card-custom">
      <!-- begin::Form-->
      <b-form class="form" v-on:submit.prevent="save">
        <!--begin::Body-->
        <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <h4 class="mb-10 font-weight-bold text-dark">
                Optionen auswählen
              </h4>
              <div class="form-group">
                <div class="border-bottom mb-10 pb-5">
                  <SubscriptionPropertyLine
                    name="Grundpreis Jahresabo inkl. 1 BKP"
                    prefix="CHF"
                    :property="formatFloat(getBasePrice)"
                    :isBold="true"
                  />

                  <div class="mb-5" />

                  <div class="row">
                    <div class="col-xl-5">
                      <label class="pt-4 pb-4"
                        >Firmenlogo hinzufügen CHF
                        {{ formatFloat(getLogoPrice) }}</label
                      >
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <b-form-radio-group
                          class="pt-4 pb-4"
                          v-model="subscriptionOrder.has_logo"
                          :options="checkboxOptions"
                        ></b-form-radio-group>
                      </div>
                    </div>
                    <div class="col-xl-4 text-right">
                      <label class="pt-4 pb-4"
                        >CHF {{ formatFloat(getTotalLogoPrice) }}</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-5">
                      <label class="pt-4 pb-4"
                        >Niederlassungen hinzufügen CHF
                        {{ formatFloat(getBranchOfficesPrice) }}</label
                      >
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <b-form-radio-group
                          class="pt-4 pb-4"
                          v-model="subscriptionOrder.has_branch_offices"
                          :options="checkboxOptions"
                        ></b-form-radio-group>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <ValidatedNumberInput
                        v-model="subscriptionOrder.nr_of_branch_offices"
                        :v="$v.subscriptionOrder.nr_of_branch_offices"
                        min="0"
                        errorMessage="Der Wert muss mindestens 1 sein."
                      />
                    </div>
                    <div class="col-xl-2 text-right">
                      <label class="pt-4 pb-4"
                        >CHF
                        {{ formatFloat(getTotalBranchOfficesPrice) }}</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-5">
                      <label
                        class="pt-4 pb-4"
                        v-b-tooltip.hover.top="
                          'Bitte beachten Sie, dass 1 BKP Nummer bereits im Basispreis enthalten ist.'
                        "
                        >Zusätzliche BKP hinzufügen CHF
                        {{ formatFloat(getAdditionalBKPPrice) }}</label
                      >
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <b-form-radio-group
                          class="pt-4 pb-4"
                          v-model="subscriptionOrder.has_additional_bkp"
                          :options="checkboxOptions"
                        ></b-form-radio-group>
                      </div>
                    </div>
                    <div class="col-xl-2">
                      <ValidatedNumberInput
                        v-model="subscriptionOrder.nr_of_additional_bkp"
                        :v="$v.subscriptionOrder.nr_of_additional_bkp"
                        min="0"
                        errorMessage="Der Wert muss mindestens 1 sein."
                        :helpText="'Total BKPs = ' + getTotalNrOfBKP"
                        helpTextVariant="info"
                      />
                    </div>
                    <div class="col-xl-2 text-right">
                      <label class="pt-4 pb-4"
                        >CHF
                        {{ formatFloat(getTotalAdditionalBKPPrice) }}</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-5">
                      <label class="pt-0 pb-4"
                        >Firmenprofil hinzufügen CHF
                        {{ formatFloat(getPortraitPrice) }}</label
                      >
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group">
                        <b-form-radio-group
                          class="pt-0 pb-4"
                          v-model="subscriptionOrder.has_portrait"
                          :options="checkboxOptions"
                        ></b-form-radio-group>
                      </div>
                    </div>
                    <div class="col-xl-4 text-right">
                      <label class="pt-0 pb-4"
                        >CHF {{ formatFloat(getTotalPortraitPrice) }}</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <h6 class="mb-10 font-weight-bold text-dark">
                        Total Abo Grundpreis mit gewählten Optionen
                      </h6>
                    </div>
                    <div class="col-xl-6 text-right">
                      <h6 class="mb-10 font-weight-bold text-dark">
                        CHF {{ formatFloat(getTotalBasePrice) }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <h4 class="mb-5 font-weight-bold text-dark">
                Laufzeit auswählen
              </h4>
              <div class="row">
                <div class="col-xl-10">
                  <label class="pt-4 pb-4">
                    Anzahl Jahre um von Rabatten zu profitieren
                  </label>
                </div>
                <div class="col-xl-2">
                  <ValidatedNumberInput
                    v-model="subscriptionOrder.nr_of_years"
                    :v="$v.subscriptionOrder.nr_of_years"
                    min="1"
                    max="5"
                    :errorMessage="
                      'Der Wert muss zwischen  ' +
                        $v.subscriptionOrder.nr_of_years.$params.minValue.min +
                        ' und ' +
                        $v.subscriptionOrder.nr_of_years.$params.maxValue.max +
                        ' Jahre liegen.'
                    "
                  />
                </div>
              </div>

              <SubscriptionPropertyLine
                name="Rabatt Aktivierungscode"
                :property="formatFloat(getInvitationDiscountPercentage)"
                postfix="%"
              />
              <SubscriptionPropertyLine
                class="pt-5"
                name="Abokosten Listenpreis"
                prefix="CHF"
                :property="formatFloat(getTotalListPrice)"
              />
              <div v-if="showYearDetails">
                <SubscriptionPropertyLine
                  name="Abokosten 1. Jahr"
                  prefix="CHF"
                  :property="formatFloat(getYearPriceDiscount(1))"
                  :additional-prefix="
                    formatFloat(getDiscountPercentage(1)) + '% Rabatt'
                  "
                />
                <SubscriptionPropertyLine
                  v-if="subscriptionOrder.nr_of_years >= 2"
                  name="Abokosten 2. Jahr"
                  prefix="CHF"
                  :property="formatFloat(getYearPriceDiscount(2))"
                  :additional-prefix="
                    formatFloat(getDiscountPercentage(2)) + '% Rabatt'
                  "
                />
                <SubscriptionPropertyLine
                  v-if="subscriptionOrder.nr_of_years >= 3"
                  name="Abokosten 3. Jahr"
                  prefix="CHF"
                  :property="formatFloat(getYearPriceDiscount(3))"
                  :additional-prefix="
                    formatFloat(getDiscountPercentage(3)) + '% Rabatt'
                  "
                />
                <SubscriptionPropertyLine
                  v-if="subscriptionOrder.nr_of_years >= 4"
                  name="Abokosten 4. Jahr"
                  prefix="CHF"
                  :property="formatFloat(getYearPriceDiscount(4))"
                  :additional-prefix="
                    formatFloat(getDiscountPercentage(4)) + '% Rabatt'
                  "
                />
                <SubscriptionPropertyLine
                  v-if="subscriptionOrder.nr_of_years >= 5"
                  name="Abokosten 5. Jahr"
                  prefix="CHF"
                  :property="formatFloat(getYearPriceDiscount(5))"
                  :additional-prefix="
                    formatFloat(getDiscountPercentage(5)) + '% Rabatt'
                  "
                />
              </div>
              <SubscriptionPropertyLine
                name="Gesamtkosten nach Abzug Rabatt"
                :property="formatFloat(getTotalDiscountPrice)"
                prefix="CHF"
                :is-bold="showYearDetails"
              />

              <SubscriptionPropertyLine
                name="Ersparnis"
                :property="
                  formatFloat(getTotalListPrice - getTotalDiscountPrice)
                "
                prefix="CHF"
              />

              <SubscriptionPropertyLine
                name="Ersparnis in %"
                :property="formatFloat(getTotalDiscountPercentage)"
                postfix="%"
              />

              <SubscriptionPropertyLine
                name="Durchschnittspreis Jahresabo"
                :property="
                  formatFloat(
                    getTotalDiscountPrice / this.subscriptionOrder.nr_of_years
                  )
                "
                prefix="CHF"
              />

              <!--              <div class="row pt-5">-->
              <!--                <div class="col-xl-9">-->
              <!--                  <label class="pt-4 pb-4"-->
              <!--                    >Möchten Sie alles in einer Rechnung bezahlen? Dann gewähren-->
              <!--                    wir Ihnen ab dem 2 Jahr pro Jahr zusätzlich 2.0% Skonto. Das-->
              <!--                    bedeutet, dass Sie zum Beispiel bei einer Abolaufzeit von 5-->
              <!--                    Jahren zusätzlich 8% Skonto erhalten.-->
              <!--                  </label>-->
              <!--                </div>-->
              <!--                <div class="col-xl-3 text-right">-->
              <!--                  <div class="form-group">-->
              <!--                    <b-form-radio-group-->
              <!--                      class="pt-4 pb-4"-->
              <!--                      v-model="subscriptionOrder.has_cash_discount"-->
              <!--                      :options="checkboxOptions"-->
              <!--                    ></b-form-radio-group>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="row pt-5">
                <div class="col-xl-12">
                  <label class="pt-4 pb-4"
                    >Zusätzlich gewähren wir Ihnen ab dem 2 Jahr pro Jahr
                    zusätzlich 2.0% Skonto. Das bedeutet, dass Sie zum Beispiel
                    bei einer Abolaufzeit von 5 Jahren zusätzlich 8% Skonto
                    erhalten.
                  </label>
                </div>
              </div>

              <SubscriptionPropertyLine
                name="Skonto"
                prefix="CHF"
                :property="
                  formatFloat(getTotalDiscountPrice - getTotalCashDiscountPrice)
                "
                :additional-prefix="
                  formatFloat(getCashDiscountPercentage) + '%'
                "
              />
              <SubscriptionPropertyLine
                name="Gesamtkosten nach Abzug Skonto"
                prefix="CHF"
                :property="formatFloat(getTotalCashDiscountPrice)"
              />
              <SubscriptionPropertyLine
                name="Durchschnittspreis Jahresabo"
                prefix="CHF"
                :property="
                  formatFloat(
                    getTotalCashDiscountPrice /
                      this.subscriptionOrder.nr_of_years
                  )
                "
              />
              <SubscriptionPropertyLine
                class="pt-5"
                name="Total Ersparnis gegenüber dem Listenpreis"
                prefix="CHF"
                :property="
                  formatFloat(getTotalListPrice - getTotalCashDiscountPrice)
                "
              />
              <SubscriptionPropertyLine
                name="Total Ersparnis in %"
                :property="formatFloat(getTotalSubscriptionPercentage)"
                postfix="%"
              />

              <div class="pt-5" />

              <SubscriptionPropertySummaryLine
                name="Total Kosten"
                prefix="CHF"
                :property="formatFloat(getTotalCashDiscountPrice)"
              />

              <!--begin: Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2"></div>
                <div>
                  <button
                    class="btn btn-danger font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                    :disabled="!subscriptionOrder.is_adjustment_allowed"
                  >
                    Kostenpflichtig bestellen
                  </button>
                </div>
              </div>
              <!--end: Actions -->
            </div>
          </div>
        </div>
        <!--end::Body-->
      </b-form>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { alertMixin } from "@/mixins/alertMixin";
import { validationMixin } from "vuelidate";
import {
  required,
  numeric,
  minValue,
  maxValue
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ValidatedNumberInput from "@/components/ValidatedNumberInput";
import SubscriptionPropertyLine from "@/components/SubscriptionPropertyLine";
import SubscriptionPropertySummaryLine from "@/components/SubscriptionPropertySummaryLine";
import {
  LOAD_SUBSCRIPTION_PRICING,
  LOAD_SUBSCRIPTION_ORDER,
  ADJUST_SUBSCRIPTION
} from "@/store/subscription.module";
import { LOAD_USER_PROFILE } from "@/store/profile.module";
import { LOAD_USER_INVITATION_INVITED_USER } from "@/store/userinvitations.module";

function nrOfBranchOfficesValidation(value) {
  return this.validateNrOfBranchOffices(value);
}

function nrOfAdditionalBkpValidation(value) {
  return this.validateNrOfAdditionalBkp(value);
}

export default {
  components: {
    ValidatedNumberInput,
    SubscriptionPropertyLine,
    SubscriptionPropertySummaryLine
  },
  mixins: [validationMixin, utilMixin, alertMixin],
  name: "AdjustSubscription",
  data() {
    return {
      subscriptionOrder: {
        has_logo: false,
        has_branch_offices: false,
        nr_of_branch_offices: 1,
        has_additional_bkp: false,
        nr_of_additional_bkp: 1,
        has_portrait: false,
        nr_of_years: 1,
        has_cash_discount: true
      },
      userInvitation: null,
      isLoaded: false,
      checkboxOptions: [
        { text: "Nein", value: false },
        { text: "Ja", value: true }
      ],
      showYearDetails: true
    };
  },
  validations: {
    subscriptionOrder: {
      nr_of_branch_offices: {
        nrOfBranchOfficesValidation,
        numeric
      },
      nr_of_additional_bkp: {
        nrOfAdditionalBkpValidation,
        numeric
      },
      nr_of_years: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(5)
      }
    }
  },
  mounted() {
    this.loadSubscription();
  },
  methods: {
    async loadSubscription() {
      // Load first the pricing before the company is loaded
      await Promise.all([
        this.$store.dispatch(LOAD_SUBSCRIPTION_PRICING),
        this.$store.dispatch(LOAD_SUBSCRIPTION_ORDER),
        this.$store.dispatch(LOAD_USER_INVITATION_INVITED_USER)
      ]).then(() => {
        this.subscriptionOrder = this.getSubscriptionOrder;
        this.userInvitation = this.getUserInvitationInvitedUser;
      });
      this.isLoaded = true;
    },
    save() {
      this.$v.subscriptionOrder.$touch();
      if (this.$v.subscriptionOrder.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      Swal.fire({
        title: "",
        text: "Bitte bestätigen Sie ihre Bestellung.",
        icon: "info",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger"
        },
        confirmButtonText: "Bestellen!",
        cancelButtonText: "Abbrechen",
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(ADJUST_SUBSCRIPTION, this.subscriptionOrder)
            .then(() => {
              // console.log(response);
              // Reload profile after update the subscription to reload the user registration completed flag.
              this.$store.dispatch(LOAD_USER_PROFILE).then(() => {
                // console.log(response);
                this.showNotification();
              });
              // .catch(error => {
              //   console.error(error);
              // });
            });
          // .catch(error => {
          //   console.error(error);
          // });
        }
      });
    },
    showNotification() {
      Swal.fire({
        title: "",
        text:
          "Ihr Abonoment wurde erfolgreich angepasst. Sie können Ihre Daten jetzt bearbeiten!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-secondary"
        },
        willClose: () => {
          this.$router.push({ name: "dashboard" });
        }
      });
    },
    validateNrOfBranchOffices(value) {
      if (this.subscriptionOrder.has_branch_offices) {
        return value > 0;
      }
      return true;
    },
    validateNrOfAdditionalBkp(value) {
      if (this.subscriptionOrder.has_additional_bkp) {
        return value > 0;
      }
      return true;
    },
    getYearPriceDiscount(nrOfYears) {
      return (
        (this.getTotalBasePrice / 100) *
        (100 - this.getDiscountPercentage(nrOfYears))
      );
    },
    getDiscountPercentage(nrOfYears) {
      let discountPercentage = 0;
      if (nrOfYears > 1) {
        discountPercentage =
          (nrOfYears - 1) *
          this.getSubscriptionPricing.discount_additional_years;
      }
      return discountPercentage + this.getInvitationDiscountPercentage;
    }
  },
  computed: {
    ...mapGetters([
      "getSubscriptionPricing",
      "getSubscriptionOrder",
      "getUserInvitationInvitedUser"
    ]),
    getBasePrice() {
      if (this.getSubscriptionPricing.base_subscription != null) {
        return this.getSubscriptionPricing.base_subscription;
      }
      return 0;
    },
    getLogoPrice() {
      if (this.getSubscriptionPricing.logo != null) {
        return this.getSubscriptionPricing.logo;
      }
      return 0;
    },
    getBranchOfficesPrice() {
      if (this.getSubscriptionPricing.branch_office != null) {
        return this.getSubscriptionPricing.branch_office;
      }
      return 0;
    },
    getAdditionalBKPPrice() {
      if (this.getSubscriptionPricing.additional_bkp != null) {
        return this.getSubscriptionPricing.additional_bkp;
      }
      return 0;
    },
    getPortraitPrice() {
      if (this.getSubscriptionPricing.portrait != null) {
        return this.getSubscriptionPricing.portrait;
      }
      return 0;
    },
    getTotalLogoPrice() {
      if (this.subscriptionOrder.has_logo) {
        return this.getSubscriptionPricing.logo;
      }
      return 0;
    },
    getTotalBranchOfficesPrice() {
      if (this.subscriptionOrder.has_branch_offices) {
        return (
          parseInt(this.subscriptionOrder.nr_of_branch_offices) *
          this.getSubscriptionPricing.branch_office
        );
      }
      return 0;
    },
    getTotalNrOfBKP() {
      if (this.subscriptionOrder.has_additional_bkp) {
        return parseInt(this.subscriptionOrder.nr_of_additional_bkp) + 1;
      }
      return 1;
    },
    getTotalAdditionalBKPPrice() {
      if (this.subscriptionOrder.has_additional_bkp) {
        return (
          parseInt(this.subscriptionOrder.nr_of_additional_bkp) *
          this.getSubscriptionPricing.additional_bkp
        );
      }
      return 0;
    },
    getTotalPortraitPrice() {
      if (this.subscriptionOrder.has_portrait) {
        return this.getSubscriptionPricing.portrait;
      }
      return 0;
    },
    getTotalBasePrice() {
      let total = this.getSubscriptionPricing.base_subscription;
      total += this.getTotalLogoPrice;
      total += this.getTotalBranchOfficesPrice;
      total += this.getTotalAdditionalBKPPrice;
      total += this.getTotalPortraitPrice;
      return total;
    },
    getTotalListPrice() {
      return (
        parseInt(this.subscriptionOrder.nr_of_years) * this.getTotalBasePrice
      );
    },
    // Discount for additional years
    getTotalDiscountPercentage() {
      return 100 - (this.getTotalDiscountPrice / this.getTotalListPrice) * 100;
    },
    getTotalDiscountPrice() {
      let discountPrice = 0;
      for (let i = 0; i < this.subscriptionOrder.nr_of_years; i++) {
        discountPrice += this.getYearPriceDiscount(i + 1);
      }
      return discountPrice;
    },
    getInvitationDiscountPercentage() {
      if (this.userInvitation !== null) {
        if (!this.userInvitation.is_discount_used) {
          return this.userInvitation.invitation_discount;
        }
      }
      return 0.0;
    },
    getTotalCashDiscountPrice() {
      return (
        (this.getTotalDiscountPrice / 100) *
        (100 - this.getCashDiscountPercentage)
      );
    },
    getCashDiscountPercentage() {
      if (this.subscriptionOrder.has_cash_discount) {
        return (
          (this.subscriptionOrder.nr_of_years - 1) *
          this.getSubscriptionPricing.cash_discount_additional_years
        );
      }
      return 0;
    },
    getTotalSubscriptionPercentage() {
      return (
        100 - (this.getTotalCashDiscountPrice / this.getTotalListPrice) * 100
      );
    }
  }
};
</script>
