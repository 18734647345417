<template>
  <div class="form-group">
    <label v-if="name">{{ name }}</label>
    <b-form-input
      class="form-control form-control-lg form-control-solid"
      type="number"
      v-model="input"
      :min="min"
      :max="max"
      :state="validateState()"
      aria-describedby="input-live-feedback"
    />
    <b-form-invalid-feedback id="input--live-feedback">
      {{ errorMessage }}
    </b-form-invalid-feedback>
    <!-- This is a form text block (formerly known as help block) -->
    <b-form-text :text-variant="helpTextVariant" v-if="helpText">{{
      helpText
    }}</b-form-text>
    <b-form-text v-if="showFormattedNumber">
      {{ formattedNumberPrefix }} {{ formattedNumber }}
    </b-form-text>
  </div>
</template>
<script>
import { utilMixin } from "@/mixins/utilMixin";
export default {
  mixins: [utilMixin],
  name: "ValidatedNumberInput",
  props: {
    min: {
      String,
      default: ""
    },
    max: {
      String,
      default: ""
    },
    name: String,
    value: [String, Number],
    v: {
      Type: Object,
      required: true
    },
    errorMessage: String,
    helpText: String,
    helpTextVariant: {
      String,
      default: "muted"
    },
    showFormattedNumber: {
      Boolean,
      default: false
    },
    formattedNumberPrefix: {
      String,
      default: ""
    }
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.v.$touch();
        this.$emit("input", value);
      }
    },
    formattedNumber() {
      if (this.input !== undefined && this.input !== "") {
        return this.formatInt(parseInt(this.input));
      }
      return "";
    }
  },
  methods: {
    validateState() {
      const { $dirty, $error } = this.v;
      return $dirty ? !$error : null;
    }
  }
};
</script>
