<template>
  <div class="row">
    <div class="col-xl-10">
      <h6 class="mb-10 font-weight-bold text-dark">
        {{ name }}
      </h6>
    </div>
    <div class="col-xl-2 text-right">
      <h6 class="mb-10 font-weight-bold text-dark">
        {{ prefix }}
        {{ property }}
        {{ postfix }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscriptionPropertySummaryLine",
  props: {
    name: {
      String,
      required: true
    },
    prefix: {
      String,
      default: ""
    },
    property: {
      required: true
    },
    postfix: {
      String,
      default: ""
    }
  }
};
</script>

<style scoped></style>
